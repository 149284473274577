import { PricingPlanBenefitsServer } from '@wix/ambassador-pricing-plan-benefits-server/http';
import {
  Balance,
  GetBalanceRequest,
} from '@wix/ambassador-pricing-plan-benefits-server/types';
import {
  BulkRequest,
  BulkResponse,
  GetActiveFeaturesResponse,
  ServicesCatalogServer,
} from '@wix/ambassador-services-catalog-server/http';
import { queryServiceOptionsAndVariants } from '@wix/ambassador-bookings-catalog-v1-service-options-and-variants/http';
import { ServiceOptionsAndVariants } from '@wix/ambassador-bookings-catalog-v1-service-options-and-variants/types';
import {
  mapCatalogResourceResponseToStaffMember,
  mapCatalogServiceResponseToService,
  mapResponseToBusinessInfo,
} from '@wix/bookings-uou-mappers';
import { BusinessInfoBase, ServiceType } from '@wix/bookings-uou-types';
import {
  QueryAvailabilityRequest,
  QueryAvailabilityResponse,
  Slot,
} from '@wix/ambassador-availability-calendar/types';
import { AvailabilityCalendar } from '@wix/ambassador-availability-calendar/http';
import { Experiments, IHttpClient } from '@wix/yoshi-flow-editor';
import {
  Booking,
  CheckoutApiConfig,
  createConfig,
  getBooking,
  reschedule,
} from '@wix/bookings-checkout-api';
import { CalendarCatalogData } from './types';
import { Preset } from '../types/types';
import { isWeeklyTimeTableWidget } from '../utils/presets';

interface CatalogDataFilter {
  servicesOptions?: {
    slug?: string;
    include: boolean;
  };
  resourcesOptions?: {
    slug?: string;
    include: boolean;
  };
  locationsOptions?: {
    businessLocations?: string[];
    include: boolean;
  };
}

export const CATALOG_SERVER_URL = '_api/services-catalog';
export const AVAILABILITY_SERVER_URL = '_api/availability-calendar';
export const CHECKOUT_SERVER_URL = '_api/checkout-server';
export const PRICING_PLAN__BENEFITS_SERVER_API = '_api/pricing-plan-benefits';
export const SERVICE_OPTIONS_AND_VARIANTS_SERVER_API =
  '_api/service-options-and-variants';

export class BookingsApi {
  private authorization: string;
  private experiments: Experiments;
  private bookingsCheckoutApiConfig: CheckoutApiConfig;
  private httpClient: IHttpClient;
  private catalogServer: ReturnType<typeof ServicesCatalogServer>;
  private availabilityCalendarServer: ReturnType<typeof AvailabilityCalendar>;
  private pricingPlanBenefitsServer: ReturnType<
    typeof PricingPlanBenefitsServer
  >;

  constructor({
    authorization,
    baseUrl,
    experiments,
    httpClient,
  }: {
    authorization: string;
    baseUrl: string;
    experiments: Experiments;
    httpClient: IHttpClient;
  }) {
    this.authorization = authorization;
    this.experiments = experiments;
    this.bookingsCheckoutApiConfig = createConfig({
      getAuthorization: () => authorization,
      getBaseUrl: () => baseUrl,
      experiments: experiments as any,
      httpClient: httpClient as any,
    });
    this.httpClient = httpClient;

    this.catalogServer = ServicesCatalogServer(
      `${baseUrl}${CATALOG_SERVER_URL}`,
    );
    this.availabilityCalendarServer = AvailabilityCalendar(
      `${baseUrl}${AVAILABILITY_SERVER_URL}`,
    );
    this.pricingPlanBenefitsServer = PricingPlanBenefitsServer(
      `${baseUrl}${PRICING_PLAN__BENEFITS_SERVER_API}`,
    );
  }

  async getCatalogData({
    servicesOptions,
    resourcesOptions,
    locationsOptions,
    preset,
  }: CatalogDataFilter & { preset: Preset }): Promise<CalendarCatalogData> {
    let serviceVariants;
    const servicesCatalogService = this.catalogServer.Bulk();

    const bulkRequest: BulkRequest = this.createBulkRequest({
      servicesOptions,
      resourcesOptions,
      locationsOptions,
      preset,
    });
    const catalogData: BulkResponse = await servicesCatalogService({
      Authorization: this.authorization,
    }).get(bulkRequest);

    const services = servicesOptions?.include
      ? catalogData.responseServices!.services!.map((service) =>
          mapCatalogServiceResponseToService(service),
        )
      : [];
    const businessInfo: BusinessInfoBase = mapResponseToBusinessInfo(
      catalogData.responseBusiness!,
    );
    const activeFeatures: GetActiveFeaturesResponse =
      catalogData.responseBusiness!.activeFeatures!;

    const staffMembers = catalogData.responseResources?.resources?.map(
      mapCatalogResourceResponseToStaffMember,
    );
    if (
      this.experiments.enabled('specs.bookings.dynamicPricingUoU') &&
      services[0]?.payment.paymentDetails.isVariedPricing
    ) {
      serviceVariants = await this.getServiceVariants(services[0].id);
    }

    return {
      services,
      businessInfo,
      activeFeatures,
      staffMembers,
      serviceVariants,
      seoData: catalogData.responseServices?.services || [],
    };
  }

  async getServiceVariants(
    serviceId: string,
  ): Promise<ServiceOptionsAndVariants | undefined> {
    try {
      const {
        data: { serviceOptionsAndVariantsList },
      } = await this.httpClient.request(
        queryServiceOptionsAndVariants({
          query: {
            filter: {
              serviceId,
            },
          },
        }),
      );
      return serviceOptionsAndVariantsList?.[0];
    } catch (e) {
      console.log(e);
      return undefined;
    }
  }

  async getSlotsAvailability(
    queryAvailabilityRequest: QueryAvailabilityRequest,
  ): Promise<QueryAvailabilityResponse> {
    const availabilityCalendarService =
      this.availabilityCalendarServer.AvailabilityCalendar();

    const availability: QueryAvailabilityResponse =
      await availabilityCalendarService({
        Authorization: this.authorization,
        'X-Time-Budget': 30000,
      }).queryAvailability(queryAvailabilityRequest);

    return availability;
  }

  async getBookingDetails(bookingId: string) {
    return getBooking({ bookingId }, this.bookingsCheckoutApiConfig);
  }

  async rescheduleBooking({ booking, slot }: { booking: Booking; slot: Slot }) {
    return reschedule({ booking, slot }, this.bookingsCheckoutApiConfig);
  }

  private createBulkRequest({
    servicesOptions,
    resourcesOptions,
    locationsOptions,
    preset,
  }: CatalogDataFilter & { preset: Preset }): BulkRequest {
    const shouldFetchOnlyClasses = isWeeklyTimeTableWidget(preset);
    return {
      requestServices: servicesOptions?.include
        ? {
            includeDeleted: false,
            query: {
              fieldsets: [],
              filter: JSON.stringify({
                'slugs.name': servicesOptions.slug
                  ? servicesOptions.slug
                  : undefined,
                'service.customProperties.uouHidden': 'false',
                ...(locationsOptions?.include
                  ? {
                      'service.schedules.availability.locations.businessLocation.ids':
                        locationsOptions?.businessLocations,
                    }
                  : {}),
                'schedules.tags': shouldFetchOnlyClasses
                  ? `${ServiceType.GROUP}`
                  : undefined,
              }),
              paging: {
                limit: 500,
              },
              fields: [],
              sort: [],
            },
          }
        : undefined,
      requestBusiness: {
        suppressNotFoundError: false,
      },
      requestListResources: resourcesOptions?.include
        ? {
            query: {
              fieldsets: [],
              filter: resourcesOptions.slug
                ? `{"slugs.name": "${resourcesOptions.slug}"}`
                : null,
              paging: {
                limit: 500,
              },
              fields: [],
              sort: [],
            },
          }
        : undefined,
    };
  }

  async getPurchasedPricingPlans({
    contactId,
    authorization,
  }: {
    contactId: string;
    authorization: string;
  }): Promise<Balance[]> {
    const balanceRequest: GetBalanceRequest = {
      contactId,
    };
    const benefits = await this.pricingPlanBenefitsServer
      .MemberBenefits()({ Authorization: authorization })
      .getBalance(balanceRequest);
    return benefits.balanceItems || [];
  }
}
