import { Service, ServiceType } from '@wix/bookings-uou-types';
import {
  BookingsQueryParams,
  WixOOISDKAdapter,
} from '@wix/bookings-adapter-ooi-wix-sdk';
import {
  QueryAvailabilityRequest,
  QueryAvailabilityResponse,
  Slot,
  SlotAvailability,
} from '@wix/ambassador-availability-calendar/types';
import {
  convertRfcTimeToLocalDateTimeStartOfDay,
  getTodayLocalDateTimeStartOfDay,
} from '../utils/dateAndTime/dateAndTime';
import { createDummyCatalogData } from './dummyData/dummyCatalogData';
import { createDummySlots } from './dummyData/dummySlotsData';
import { BookingsApi } from './BookingsApi';
import { CalendarApiInitParams, CalendarCatalogData } from './types';
import {
  CalendarErrors,
  LocalDateTimeRange,
  Optional,
  Preset,
  SlotsAvailability,
} from '../types/types';
import {
  areAllLocationsSelected,
  filterServicesBySelectedLocations,
  filterSlotsBySelectedLocations,
  isOnlyBusinessLocationsSelected,
  isOtherLocationsSelected,
} from '../utils/selectedLocations/selectedLocations';
import { EmptyStateType } from '../components/BookingCalendar/ViewModel/emptyStateViewModel/emptyStateViewModel';
import { CalendarState } from '../components/BookingCalendar/controller';
import { ControllerFlowAPI, ControllerParams } from '@wix/yoshi-flow-editor';
import { AddError } from '../components/BookingCalendar/Actions/addError/addError';
import { createDummyDateAvailability } from './dummyData/dummyDateAvailability';
import { Balance } from '@wix/ambassador-pricing-plan-benefits-server/types';
import { Booking } from '@wix/bookings-checkout-api';
import { isCalendarPage } from '../utils/presets';
import { isCalendarFlow } from '../utils/serviceUtils/serviceUtils';
import { isLayoutWithTimeSlot } from '../utils/layouts';

export const CALENDAR_PAGE_URL_PATH_PARAM = 'booking-calendar';

export class CalendarApi {
  private readonly flowAPI: ControllerFlowAPI;
  private wixSdkAdapter: WixOOISDKAdapter;
  private bookingsApi: BookingsApi;
  private readonly reportError: ControllerParams['flowAPI']['reportError'];
  private readonly settingsParams: any;
  private readonly preset: Preset;

  constructor({
    flowAPI,
    wixSdkAdapter,
    reportError,
    settingsParams,
    preset,
  }: CalendarApiInitParams) {
    this.flowAPI = flowAPI;
    this.settingsParams = settingsParams;
    this.wixSdkAdapter = wixSdkAdapter;
    this.reportError = reportError;
    this.preset = preset;
    this.bookingsApi = new BookingsApi({
      authorization: wixSdkAdapter.getInstance(),
      baseUrl: wixSdkAdapter.getServerBaseUrl(),
      experiments: flowAPI.experiments,
      httpClient: flowAPI.httpClient,
    });
  }

  async getCatalogData({
    onError,
  }: {
    onError: (type: EmptyStateType) => void;
  }): Promise<Optional<CalendarCatalogData>> {
    if (this.wixSdkAdapter.isEditorMode()) {
      const catalogData = await this.bookingsApi.getCatalogData({
        servicesOptions: {
          include: false,
        },
        resourcesOptions: {
          include: false,
        },
        locationsOptions: {
          include: false,
        },
        preset: this.preset,
      });
      const dummyCatalogData = createDummyCatalogData(this.flowAPI);
      dummyCatalogData.businessInfo = catalogData.businessInfo;
      return dummyCatalogData;
    }

    const serviceSlug = await this.wixSdkAdapter.getServiceSlug(
      CALENDAR_PAGE_URL_PATH_PARAM,
    );

    const resourceSlug = this.getResourceSlug();
    const selectedLocations = this.flowAPI.settings.get(
      this.settingsParams.selectedLocations,
    );

    try {
      const locationsOptions = {
        include: this.shouldFilterBySelectedLocationsOnServerSide(),
        businessLocations: selectedLocations,
      };
      const catalogData = await this.bookingsApi.getCatalogData({
        servicesOptions: {
          slug: serviceSlug,
          include: true,
        },
        resourcesOptions: {
          slug: resourceSlug,
          include: !!resourceSlug,
        },
        locationsOptions,
        preset: this.preset,
      });

      if (isCalendarPage(this.preset)) {
        catalogData.services = this.filterNotBookableServicesFromCalendarPage(
          serviceSlug,
          catalogData.services,
        );
      }

      if (this.shouldFilterBySelectedLocationsOnClientSide()) {
        catalogData.services = filterServicesBySelectedLocations(
          selectedLocations,
          catalogData.services,
        );
      }

      const { services } = catalogData;

      if (!services.length || !services[0]) {
        onError(EmptyStateType.SERVICE_NOT_FOUND);
        return;
      }

      return catalogData;
    } catch (e) {
      this.reportError(e);
      onError(EmptyStateType.SERVER_ERROR);
    }
  }

  async getNextAvailableDate(
    { fromAsLocalDateTime, toAsLocalDateTime }: LocalDateTimeRange,
    {
      state,
      onError,
    }: {
      state: CalendarState;
      onError: AddError;
    },
  ): Promise<Optional<string>> {
    try {
      const availabilityCalendarRequest: QueryAvailabilityRequest =
        this.buildQueryAvailabilityRequest({
          from: fromAsLocalDateTime,
          to: toAsLocalDateTime,
          state,
          getNextAvailableSlot: true,
        });
      const slotAvailability = await this.bookingsApi.getSlotsAvailability(
        availabilityCalendarRequest,
      );

      const nextAvailableDate =
        slotAvailability?.availabilityEntries?.[0]?.slot?.startDate;
      if (nextAvailableDate) {
        const nextAvailable = convertRfcTimeToLocalDateTimeStartOfDay(
          nextAvailableDate!,
        );
        return nextAvailable;
      }
      onError(CalendarErrors.NO_NEXT_AVAILABLE_DATE_WARNING);
    } catch (e) {
      this.reportError(e);
      onError(CalendarErrors.NEXT_AVAILABLE_DATE_SERVER_ERROR);
    }
  }

  async getDateAvailability(
    { fromAsLocalDateTime, toAsLocalDateTime }: LocalDateTimeRange,
    {
      state,
    }: {
      state: CalendarState;
    },
  ): Promise<Optional<QueryAvailabilityResponse>> {
    if (this.wixSdkAdapter.isEditorMode()) {
      return createDummyDateAvailability();
    }

    try {
      let from;
      const { selectedTimezone } = state;
      const todayLocalDateTime = getTodayLocalDateTimeStartOfDay(
        selectedTimezone!,
      );
      if (new Date(toAsLocalDateTime) < new Date(todayLocalDateTime)) {
        return {};
      } else {
        from =
          new Date(todayLocalDateTime) > new Date(fromAsLocalDateTime)
            ? todayLocalDateTime
            : fromAsLocalDateTime;
      }
      const availabilityCalendarRequest: QueryAvailabilityRequest =
        this.buildQueryAvailabilityRequest({
          from,
          to: toAsLocalDateTime,
          state,
          shouldLimitPerDay: true,
        });

      return await this.bookingsApi.getSlotsAvailability(
        availabilityCalendarRequest,
      );
    } catch (e) {
      this.reportError(e);
    }
  }

  async getSlotsInRange(
    { fromAsLocalDateTime, toAsLocalDateTime }: LocalDateTimeRange,
    {
      state,
      onError,
    }: {
      state: CalendarState;
      onError: AddError;
    },
  ): Promise<Optional<QueryAvailabilityResponse>> {
    if (this.wixSdkAdapter.isEditorMode()) {
      const calendarLayout = this.flowAPI.settings.get(
        this.settingsParams.calendarLayout,
      );
      return createDummySlots(this.flowAPI, {
        from: fromAsLocalDateTime,
        calendarLayout,
      });
    }

    try {
      const availabilityCalendarRequest: QueryAvailabilityRequest =
        this.buildQueryAvailabilityRequest({
          from: fromAsLocalDateTime,
          to: toAsLocalDateTime,
          state,
        });

      const slotAvailability = await this.bookingsApi.getSlotsAvailability(
        availabilityCalendarRequest,
      );

      if (isCalendarPage(this.preset)) {
        slotAvailability.availabilityEntries =
          this.getOnlyFutureSlotAvailabilities(
            slotAvailability?.availabilityEntries,
          );
      }

      if (this.shouldFilterBySelectedLocationsOnClientSide()) {
        const selectedLocations = this.flowAPI.settings.get(
          this.settingsParams.selectedLocations,
        );
        slotAvailability.availabilityEntries = filterSlotsBySelectedLocations(
          selectedLocations,
          slotAvailability?.availabilityEntries,
        );
      }

      return slotAvailability;
    } catch (e) {
      this.reportError(e);
      onError(CalendarErrors.AVAILABLE_SLOTS_SERVER_ERROR);
    }
  }

  async getBookingDetails({
    onError,
  }: {
    onError: (type: EmptyStateType) => void;
  }): Promise<Optional<Booking>> {
    const bookingId = this.wixSdkAdapter.getUrlQueryParamValue(
      BookingsQueryParams.BOOKING_ID,
    );
    if (!bookingId || this.wixSdkAdapter.isSSR()) {
      return;
    }
    try {
      return await this.bookingsApi.getBookingDetails(bookingId);
    } catch (e) {
      this.reportError(e);
      const errorType =
        e?.httpStatus === 403
          ? EmptyStateType.GET_BOOKING_DETAILS_ACCESS_DENIED
          : EmptyStateType.GET_BOOKING_DETAILS_ERROR;
      onError(errorType);
    }
  }

  async rescheduleBooking({
    booking,
    slot,
    onError,
  }: {
    booking: Booking;
    slot: Slot;
    onError: AddError;
  }) {
    try {
      return this.bookingsApi.rescheduleBooking({ booking, slot });
    } catch (e) {
      this.reportError(e);
      onError(CalendarErrors.RESCHEDULE_SERVER_ERROR);
    }
  }

  async getPurchasedPricingPlans({
    contactId,
  }: {
    contactId: string;
  }): Promise<Balance[]> {
    try {
      if (!this.wixSdkAdapter.isEditorMode()) {
        return await this.bookingsApi.getPurchasedPricingPlans({
          contactId,
          authorization: this.wixSdkAdapter.getInstance(),
        });
      }
      return [];
    } catch (e) {
      this.reportError(e);
      return [];
    }
  }

  private getOnlyFutureSlotAvailabilities(
    availableSlots?: SlotAvailability[],
  ): SlotAvailability[] {
    const now = new Date();
    const onlyFutureEntries = availableSlots?.filter((availabilityEntry) => {
      const rfcStartTime = availabilityEntry?.slot?.startDate;
      return rfcStartTime && new Date(rfcStartTime) >= now;
    });
    return onlyFutureEntries || [];
  }

  private filterNotBookableServicesFromCalendarPage = (
    serviceSlug: string,
    services: Service[],
  ) => {
    if (services?.[0] && !serviceSlug) {
      const bookableServices = services.filter((service: Service) =>
        isCalendarFlow(service),
      );
      return bookableServices.length ? [bookableServices[0]] : [];
    }
    return services;
  };

  private shouldFilterBySelectedLocationsOnClientSide() {
    const selectedLocations = this.flowAPI.settings.get(
      this.settingsParams.selectedLocations,
    );
    return (
      !isLayoutWithTimeSlot(this.flowAPI.settings, this.settingsParams) &&
      !areAllLocationsSelected(selectedLocations) &&
      isOtherLocationsSelected(selectedLocations)
    );
  }

  private shouldFilterBySelectedLocationsOnServerSide() {
    return (
      !isLayoutWithTimeSlot(this.flowAPI.settings, this.settingsParams) &&
      isOnlyBusinessLocationsSelected(
        this.flowAPI.settings.get(this.settingsParams.selectedLocations),
      )
    );
  }

  private getResourceSlug() {
    const staffQueryParam = this.wixSdkAdapter.getUrlQueryParamValue(
      BookingsQueryParams.STAFF,
    );

    if (staffQueryParam) {
      if (Array.isArray(staffQueryParam)) {
        return staffQueryParam[0];
      } else {
        return staffQueryParam;
      }
    }
  }

  private getBusinessLocationsFilterForQueryAvailabilityRequest(
    state: CalendarState,
    settings: any,
  ) {
    const { filterOptions } = state;
    const isLayoutWithTimeSlots = isLayoutWithTimeSlot(
      settings,
      this.settingsParams,
    );
    const selectedLocations = settings.get(
      this.settingsParams.selectedLocations,
    );
    const shouldFilterByBusinessLocations = isLayoutWithTimeSlots
      ? filterOptions.LOCATION?.length > 0
      : isOnlyBusinessLocationsSelected(selectedLocations);

    if (shouldFilterByBusinessLocations) {
      return {
        'location.businessLocation.id': isLayoutWithTimeSlots
          ? filterOptions.LOCATION
          : selectedLocations,
      };
    }
    return {};
  }

  private buildQueryAvailabilityRequest({
    from,
    to,
    state,
    shouldLimitPerDay = false,
    getNextAvailableSlot = false,
  }: {
    from: string;
    to: string;
    state: CalendarState;
    shouldLimitPerDay?: boolean;
    getNextAvailableSlot?: boolean;
  }): QueryAvailabilityRequest {
    const { selectedTimezone, filterOptions, availableServices } = state;
    const serviceIds =
      filterOptions.SERVICE?.length > 0
        ? filterOptions.SERVICE
        : availableServices.map((service) => `${service?.id}`);

    const onlyAvailableSlots =
      this.flowAPI.settings.get(this.settingsParams.slotsAvailability) ===
      SlotsAvailability.ONLY_AVAILABLE;

    const getNextSlotNotFullAndNotTooLateToBook =
      getNextAvailableSlot && !onlyAvailableSlots;

    const isIndividualService = availableServices.some(
      (service) => service.info.type === ServiceType.INDIVIDUAL,
    );

    const businessLocationsFilter =
      this.getBusinessLocationsFilterForQueryAvailabilityRequest(
        state,
        this.flowAPI.settings,
      );

    return {
      timezone: selectedTimezone,
      ...(shouldLimitPerDay ? { slotsPerDay: 1 } : {}),
      query: {
        filter: {
          serviceId: serviceIds,
          startDate: from,
          endDate: to,
          ...(onlyAvailableSlots ? { bookable: true } : {}),
          ...(filterOptions.STAFF_MEMBER?.length > 0
            ? { resourceId: filterOptions.STAFF_MEMBER }
            : {}),
          ...businessLocationsFilter,
          ...(isIndividualService || getNextSlotNotFullAndNotTooLateToBook
            ? { openSpots: { $gte: '1' } }
            : {}),
          ...(getNextSlotNotFullAndNotTooLateToBook
            ? { 'bookingPolicyViolations.tooLateToBook': false }
            : {}),
        },
        ...(getNextAvailableSlot ? { cursorPaging: { limit: 1 } } : {}),
      },
    };
  }
}
